// eslint-disable-next-line
import { MdDashboard, MdFlight, MdPayments, MdOutlineWallet } from 'react-icons/md'
import { IoUmbrella, IoMenu } from "react-icons/io5"
// eslint-disable-next-line
// import { BsPatchCheckFill } from "react-icons/bs"
// eslint-disable-next-line
// import { GoPasskeyFill } from "react-icons/go"
import { HiMiniBuildingOffice2, HiPlusCircle } from "react-icons/hi2"
// import { FaUserTie } from "react-icons/fa"
import { FaHandshakeSimple, FaShop, FaListOl, FaLink } from "react-icons/fa6"
// import { IoDocuments } from "react-icons/io5"
import { HiUserGroup } from "react-icons/hi2"
// import { TbHelpOctagonFilled } from "react-icons/tb"
// import { IoIosContact } from "react-icons/io"
import { FaUsersCog } from "react-icons/fa"
// import { RiFileExcel2Fill } from "react-icons/ri";
import { TbHelpCircleFilled } from "react-icons/tb";
import { RiAccountCircleFill } from "react-icons/ri";
import { PiPasswordBold } from "react-icons/pi";
import { IoLogOutOutline } from "react-icons/io5";
import constants from './constants.js'
import { isMobile } from 'react-device-detect';
import { RiBarChartBoxFill } from "react-icons/ri";
import { MdCalculate } from "react-icons/md";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { MdWallet } from "react-icons/md";
import { IoMdListBox } from "react-icons/io";
import { FaPassport } from "react-icons/fa"

const navItems = {
    "/agent": [
        // { text: "Search Fare", logo: <BiSolidPlaneAlt className="rotate-45" />, link: "/agent/flights/search", id: "sk-nav-flights" },
        { text: "Travel Loan", logo: <IoMdListBox />, link: "/agent/application/check-eligibility", id: "sk-nav-new-application" },
        { text: "Insurance", logo: <IoUmbrella />, link: "/agent/insurance/new-insurance", id: "sk-nav-insurance" },
        { text: "Payment Gateway", logo: <MdPayments />, link: "/agent/collect-payment", id: "sk-nav-collect-payment" },
        { text: "My Transactions", logo: <RiBarChartBoxFill />, link: "/agent/transactions", id: "sk-nav-transactions" },
        // { text: "EMI Calculator", logo: <MdCalculate />, link: "/agent/calculate-emi", id: "sk-nav-calculate-emi" },
        // { text: "My Wallet", logo: <MdWallet />, link: "/agent/wallet", id: "sk-nav-wallet" },
        { text: "Support", logo: <TbHelpCircleFilled />, link: "/agent/support", id: "sk-nav-support" },
        // { text: "Itinerary Builder", logo: "/assets/icons/itinerary-builder.svg", link: "/agent/itinerary-builder" },
        // {
        //     text: "Utilities", logo: "/assets/icons/marketing-toolkit.svg", link: "/agent/utilities", children: [
        //         { text: "Marketing Toolkit", logo: "/assets/icons/marketing-toolkit.svg", link: "/marketing-toolkit" },
        //         { text: "Visa Photo", logo: "/assets/icons/visa-photo.svg", link: "/visa-photo" }
        //     ]
        // }
    ],
    "/backoffice": [
        {
            text: "Dashboards", logo: <MdDashboard />, link: "/backoffice/dashboards", permission: constants.BACKOFFICE_USER_PERMISSIONS.DASHBOARDS, children: [
                { text: "TNPL Applications (MTD)", logo: <MdFlight />, link: "/tnpl-applications" },
                // { text: "Daily TNPL Disbursements", logo: <BsPatchCheckFill />, link: "/tnpl-disbursements" },
                // { text: "Daily TI Policies (Net)", logo: <IoUmbrella />, link: "/ti-policies" },
                // { text: "Daily Payments Value", logo: <MdPayments />, link: "/payments-value" },
                // { text: "TNPL Key Accounts", logo: <GoPasskeyFill />, link: "/tnpl-key-accounts" },
                // { text: "TI Key Accounts", logo: <GoPasskeyFill />, link: "/ti-key-accounts" },
            ]
        },
        {
            text: "Travel Partners", logo: <FaHandshakeSimple />, link: "/backoffice/users", permission: constants.BACKOFFICE_USER_PERMISSIONS.MANAGE_PARTNERS, children: [
                { text: "Manage Partners", logo: <HiMiniBuildingOffice2 />, link: "/partners/manage" },
                // { text: "Manage Supervisors", logo: <FaUserTie />, link: "/supervisors/manage" },
                { text: "Manage Agents", logo: <FaShop />, link: "/agents/manage" },
                { text: "New Onboardings", logo: <HiPlusCircle />, link: "/partners/new" },
            ]
        },
        {
            text: "Travel Now Pay Later", logo: <MdFlight />, link: "/backoffice/tnpl", permission: constants.BACKOFFICE_USER_PERMISSIONS.TNPL, children: [
                // { text: "Check Eligibility", logo: <IoDocuments />, link: "/check-eligibility" },
                { text: "TNPL Transactions", logo: <FaListOl />, link: "/transactions" },
                // { text: "Reports", logo: <RiFileExcel2Fill />, link: "/reports" }
            ]
        },
        {
            text: "Travel Insurance", logo: <IoUmbrella />, link: "/backoffice/ti", permission: constants.BACKOFFICE_USER_PERMISSIONS.TI, children: [
                { text: "TI Transactions", logo: <FaListOl />, link: "/transactions" },
                { text: "Group Inquiries", logo: <HiUserGroup />, link: "/group-insurance" },
                { text: "Scan Passport", logo: <FaPassport />, link: "/scan-passport" },
            ]
        },
        {
            text: "Payment Gateway", logo: <FaLink />, link: "/backoffice/payment-collection/transactions", permission: constants.BACKOFFICE_USER_PERMISSIONS.PAYMENT_COLLECTION
        },
        {
            text: "Wallet", logo: <MdOutlineWallet />, link: "/backoffice/wallet", permission: constants.BACKOFFICE_USER_PERMISSIONS.WALLET, children: [
                { text: "Wallet Transactions", logo: <FaListOl />, link: "/transactions" },
                { text: "Wallet Requests", logo: <HiPlusCircle />, link: "/requests" },
            ]
        },
        {
            text: "More", logo: <IoMenu />, link: "/backoffice/more", children: [
                { text: "User Management", logo: <FaUsersCog />, link: "/admin/users/manage", permission: constants.BACKOFFICE_USER_PERMISSIONS.ADMIN },
            ],
        },
    ]
}

if (isMobile) {
    navItems['/agent'] = [
        ...navItems['/agent'].slice(0, navItems['/agent'].length - 1),
        { text: "My Wallet", logo: <MdWallet />, link: "/agent/wallet", id: "sk-nav-wallet" },
        ...navItems['/agent'].slice(navItems['/agent'].length - 1),
        {
            text: "Account", logo: <RiAccountCircleFill />, link: "/agent/user", children: [
                { text: "Change Password", logo: <PiPasswordBold />, link: "/change-password" },
                { text: "Logout", logo: <IoLogOutOutline />, link: "/logout" }
            ]
        }
    ]

    navItems['/backoffice'].push({
        text: "Account", logo: <RiAccountCircleFill />, link: "/backoffice/user", children: [
            { text: "Change Password", logo: <PiPasswordBold />, link: "/change-password" },
            { text: "Logout", logo: <IoLogOutOutline />, link: "/logout" }
        ]
    })
}

export default navItems